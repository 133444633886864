import React from 'react';
import { Helmet } from 'react-helmet';

import { COLOR_COUNT_0 } from '../colors';
import { formatCountValue } from '../utils';

import { ChordDiagram, MicroBarChart } from '../components/charts';
import { Row5050 } from '../components/grid';
import { CountMetric } from '../components/metrics';
import { Paper, PaperPadding, PaperTitle } from '../components/papers';
import { Ranking, RankingTable } from '../components/rankings';
import { AppLayout } from '../layout';

/**
 *
 */
/* eslint-disable max-lines-per-function */
export function Cities(props) {
  const { appData, charts, currentRanking, metrics } = props.pageContext;

  return (
    <AppLayout data={appData} title="Cities with the Most Tech Jobs">
      <Helmet>
        <title>Cities | StackTrends</title>
        <meta name="description" content="" />
      </Helmet>

      <div>
        <Row5050>
          <CountMetric value={`${formatCountValue(metrics.currentListingCount)} Listings`} label="Current" />

          <CountMetric value={`${formatCountValue(metrics.lifetimeListingCount)} Listings`} label="Historic" />
        </Row5050>

        <Paper>
          <PaperTitle>Job Listings Analyzed</PaperTitle>

          <PaperPadding>
            <MicroBarChart
              chartId="micro_bar_chart_cities_listing_count"
              color={COLOR_COUNT_0}
              data={charts.microBarListingCount}
            />
          </PaperPadding>
        </Paper>
      </div>

      <Paper>
        <PaperTitle>Job Ranking by City</PaperTitle>

        <Ranking>
          <RankingTable data={currentRanking} parentPath="cities" />
        </Ranking>
      </Paper>

      <Row5050>
        <Paper>
            <PaperTitle>Cities that Compete for Tech Talent</PaperTitle>
            <PaperPadding>
              <ChordDiagram chartId="chord_diagram_city_shared_listings" data={charts.chordSharedListings} />
            </PaperPadding>
          </Paper>
      </Row5050>
    </AppLayout>
  );
}

export default Cities;

// {metrics.currentMostJobsCities.map((item) => (
//   <DetailedMetric
//     text={`${item.count.toLocaleString()} Historic Jobs`}
//     title={'Most Jobs'}
//     values={[<Link to={`/cities/${item.name}`}>{item.name_display}</Link>]}
//     key={item.id}
//   />
// ))}
//
// {metrics.currentFewestJobsCities.map((item) => (
//   <DetailedMetric
//     text={`${item.count.toLocaleString()} Historic Jobs`}
//     title={'Fewest Jobs'}
//     values={[<Link to={`/cities/${item.name}`}>{item.name_display}</Link>]}
//     key={item.id}
//   />
// ))}
//
// {metrics.currentRankWinners.map((item) => (
//   <DetailedMetric
//     text={`+${item.rank_movement.toLocaleString()} Ranks`}
//     title={'Gained the Most Ranks'}
//     values={[<Link to={`/cities/${item.name}`}>{item.name_display}</Link>]}
//     key={item.id}
//   />
// ))}
//
// {metrics.currentRankLosers.map((item) => (
//   <DetailedMetric
//     text={`${item.rank_movement.toLocaleString()} Ranks`}
//     title={'Lost the Most Ranks'}
//     values={[<Link to={`/cities/${item.name}`}>{item.name_display}</Link>]}
//     key={item.id}
//   />
// ))}
